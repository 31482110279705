import Vue from  'vue';

import GenericSubNav from '../components/genericComponents/GenericSubNav.vue';
import GenericSubNavRecast from '../components/genericComponents/GenericSubNavRecast.vue';
import InsuranceSubNav from '../components/genericComponents/InsuranceSubNav.vue';
import SwButton from '../components/swComponents/SwButton.vue';
import RenderlessSmileNavigation from '../components/renderlessComponents/RenderlessSmileNavigation.vue';

// Generic Components
Vue.component('GenericDropdown', () => import('../components/genericComponents/GenericDropdown.vue'));
Vue.component('GenericSelect', () => import('../components/genericComponents/GenericSelect.vue'));
Vue.component('GenericStep', () => import('../components/genericComponents/GenericStep.vue'));
Vue.component('GenericSubNav', GenericSubNav);
Vue.component('GenericSubNavRecast', GenericSubNavRecast);
Vue.component('InsuranceSubNav', InsuranceSubNav);

// Sw Components
Vue.component('SwButton', SwButton);
Vue.component('SwCollapse', () => import('../components/swComponents/SwCollapse.vue'));
Vue.component('SwIcon', () => import('../components/swComponents/SwIcon.vue'));
Vue.component('SwModal', () => import('../components/swComponents/SwModal.vue'));
Vue.component('SwPopover', () => import('../components/swComponents/SwPopover.vue'));
Vue.component('SwTab', () => import('../components/swComponents/SwTab.vue'));
Vue.component('SwTabs', () => import('../components/swComponents/SwTabs.vue'));
Vue.component('SwTooltip', () => import('../components/swComponents/SwTooltip.vue'));

// UI Elements
Vue.component('ConfirmationForm', () => import('../components/uiElements/ConfirmationForm.vue'));
Vue.component('ConfirmationFormWithIbanModal', () => import('../components/uiElements/ConfirmationFormWithIbanModal.vue'));
Vue.component('SelectionCtaFooter', () => import('../components/uiElements/SelectionCtaFooter.vue'));
Vue.component('TheFlash', () => import('../components/uiElements/TheFlash.vue'));
Vue.component('TheHeader', () => import('../components/uiElements/TheHeader.vue'));
Vue.component('PurchasingHeader', () => import('../components/uiElements/PurchasingHeader.vue'));
Vue.component('TheNavigation', () => import('../components/uiElements/TheNavigation.vue'));
Vue.component('TheOperatorDrawer', () => import('../components/uiElements/TheOperatorDrawer.vue'));
Vue.component('ThePdfNavigation', () => import('../components/uiElements/ThePDFNavigation.vue'));
Vue.component('ThePdfViewer', () => import('../components/uiElements/ThePDFViewer.vue'));
Vue.component('TheProgressTracker', () => import('../components/uiElements/TheProgressTracker.vue'));
Vue.component('TheStartFormCar', () => import('../components/uiElements/TheStartFormCar.vue'));
Vue.component('TheStartFormHouse', () => import('../components/uiElements/TheStartFormHouse.vue'));
Vue.component('TheStartFormMotorbike', () => import('../components/uiElements/TheStartFormMotorbike.vue'));
Vue.component('TheTableOfContents', () => import('../components/uiElements/TheTableOfContents.vue'));
Vue.component('InsurancesSubMenu', () => import('../components/uiElements/InsurancesSubMenu.vue'));
Vue.component('InsurancesCarousel', () => import('../components/uiElements/InsurancesCarousel.vue'));
Vue.component('YagoReliability', () => import('../components/uiElements/YagoReliability.vue'));
Vue.component('SelectPageType', () => import('../components/uiElements/SelectPageType.vue'));
Vue.component('PurchaseJourneyBanner', () => import('../components/uiElements/PurchaseJourneyBanner.vue'));

// Renderless Components
Vue.component('RenderlessCarousel', () => import('../components/renderlessComponents/RenderlessCarousel.vue'));
Vue.component('RenderlessDeviseForm', () => import('../components/renderlessComponents/RenderlessDeviseForm.vue'));
Vue.component('RenderlessObfuscatedLinksHandler', () => import('../components/renderlessComponents/RenderlessObfuscatedLinksHandler.vue'));
Vue.component('RenderlessPasswordVisibilityToggle', () => import('../components/renderlessComponents/RenderlessPasswordVisibilityToggle.vue'));
Vue.component('RenderlessRailsFlash', () => import('../components/renderlessComponents/RenderlessRailsFlash.vue'));
Vue.component('RenderlessSmileNavigation', RenderlessSmileNavigation);
Vue.component('RenderlessTableOfContents', () => import('../components/renderlessComponents/RenderlessTableOfContents.vue'));

// Branching Sections
Vue.component('SectionLifeBranching', () => import('../sections/SectionLifeBranching.vue'));

// Profiling Sections
Vue.component('SectionCarProfiling', () => import('../sections/SectionCarProfiling.vue'));
Vue.component('SectionHouseProfiling', () => import('../sections/SectionHouseProfiling.vue'));
Vue.component('SectionHospitalizationProfiling', () => import('../sections/SectionHospitalizationProfiling.vue'));
Vue.component('SectionMotorbikeProfiling', () => import('../sections/SectionMotorbikeProfiling.vue'));
Vue.component('SectionPrivateSavingsProfiling', () => import('../sections/SectionPrivateSavingsProfiling.vue'));
Vue.component('SectionRoadTaxProfiling', () => import('../sections/SectionRoadTaxesProfiling.vue'));
Vue.component('SectionSelfEmployedSavingsProfiling', () => import('../sections/SectionSelfEmployedSavingsProfiling.vue'));

// Completion Sections
Vue.component('SectionMotorbikeCompletion', () => import('../sections/SectionMotorbikeCompletion.vue'));
Vue.component('SectionCarCompletion', () => import('../sections/SectionCarCompletion.vue'));

// Selection Sections
Vue.component('SectionCarSelection', () => import('../sections/SectionCarSelection.vue'));
Vue.component('SectionFullPackageBundleSelection', () => import('../sections/SectionFullPackageBundleSelection.vue'));
Vue.component('SectionHouseSelection', () => import('../sections/SectionHouseSelection.vue'));
Vue.component('SectionMotorbikeSelection', () => import('../sections/SectionMotorbikeSelection.vue'));
Vue.component('SectionPrivateSavingsSelection', () => import('../sections/SectionPrivateSavingsSelection.vue'));
Vue.component('SectionSelfEmployedSavingsSelection', () => import('../sections/SectionSelfEmployedSavingsSelection.vue'));
